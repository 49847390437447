import React, { Fragment, useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { BrowserRouter, Route, Routes, useParams } from "react-router-dom";
import "./App.css";
import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import Articles from "./components/Articles";
import Footer from "./components/Footer";
import Modal from "./components/Modal";
import { account, databases } from "./appwrite/services";
import { ToastContainer, toast } from "react-toastify";
import { Query } from "appwrite";
import { Carousel } from "react-responsive-carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { Button } from "./components/Button";

// import your icons
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<MainPage />} />
        <Route path="/passwordrecover" element={<RecoverPassword />} />
        <Route path="/properties/:type/:id" element={<PropertyDetails />} />
        <Route path="/contactus" element={<ContactUS />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/about" element={<About />} />
      </Routes>
    </BrowserRouter>
  );
};

const MainPage = () => {
  const [show, setShow] = useState(false);
  const openModal = () => setShow(true);
  const closeModal = () => setShow(false);
  if (show) {
    document.body.classList.add("block-overflow");
  }
  if (!show) {
    document.body.classList.remove("block-overflow");
  }
  return (
    <Fragment>
      <Modal closeModal={closeModal} show={show} />
      <Navbar openModal={openModal} closeModal={closeModal} show={show} />
      <Hero />

      <Articles />
      <Footer />
    </Fragment>
  );
};

const PropertyDetails = () => {
  let { id, type } = useParams();
  const [show, setShow] = useState(false);
  const [property, setProperty] = useState([]);
  const openModal = () => setShow(true);
  const closeModal = () => setShow(false);

  useEffect(() => {
    if (show) {
      document.body.classList.add("block-overflow");
    } else {
      document.body.classList.remove("block-overflow");
    }
  }, [show]);

  useEffect(() => {
    if (type !== "Rent" && type !== "Buy") {
      window.location.href = "/";
    }
  }, [type]);

  useEffect(() => {
    async function fetchProperty() {
      const response = await databases.listDocuments("Properties", type, [
        Query.equal("$id", id),
      ]);
      console.log(response.documents[0]);
      setProperty(response.documents[0]);
    }
    fetchProperty();
  }, [type, id]);

  return (
    <div className="min-h-screen bg-gray-50">
      <ToastContainer className="z-50" />
      <Modal closeModal={closeModal} show={show} />
      <Navbar openModal={openModal} closeModal={closeModal} show={show} />

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          <div className="rounded-xl overflow-hidden shadow-lg bg-white">
            <Carousel showThumbs={false} className="w-full">
              {property.Images?.map((image, index) => (
                <div key={`image-${index}`} className="aspect-w-16 aspect-h-9">
                  <img
                    src={image}
                    className="w-full h-full object-cover"
                    alt={`View ${index + 1} of property`}
                  />
                </div>
              ))}
            </Carousel>
          </div>

          <div className="space-y-6 lg:pl-8">
            <h1 className="text-3xl font-bold text-gray-900">
              {property.proprtyName}
            </h1>

            <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
              <div className="flex items-center space-x-3 bg-white p-4 rounded-lg shadow">
                <FontAwesomeIcon
                  icon={["fas", "money-bill"]}
                  className="text-rose-500 text-xl"
                />
                <span className="text-gray-700 font-medium">
                  {property.Price}
                </span>
              </div>

              <div className="flex items-center space-x-3 bg-white p-4 rounded-lg shadow">
                <FontAwesomeIcon
                  icon={["fas", "bed"]}
                  className="text-rose-500 text-xl"
                />
                <span className="text-gray-700 font-medium">
                  {property.beds} Beds
                </span>
              </div>

              <div className="flex items-center space-x-3 bg-white p-4 rounded-lg shadow">
                <FontAwesomeIcon
                  icon={["fas", "bath"]}
                  className="text-rose-500 text-xl"
                />
                <span className="text-gray-700 font-medium">
                  {property.Baths} Baths
                </span>
              </div>

              <div className="flex items-center space-x-3 bg-white p-4 rounded-lg shadow">
                <FontAwesomeIcon
                  icon={["fas", "couch"]}
                  className="text-rose-500 text-xl"
                />
                <span className="text-gray-700 font-medium">
                  {property.LivingRooms} Living
                </span>
              </div>

              <div className="flex items-center space-x-3 bg-white p-4 rounded-lg shadow">
                <FontAwesomeIcon
                  icon={["fas", "maximize"]}
                  className="text-rose-500 text-xl"
                />
                <span className="text-gray-700 font-medium">
                  {property.Size}
                </span>
              </div>

              <div className="flex items-center space-x-3 bg-white p-4 rounded-lg shadow">
                <FontAwesomeIcon
                  icon={["fas", "landmark"]}
                  className="text-rose-500 text-xl"
                />
                <span className="text-gray-700 font-medium">
                  {property.Type?.join(", ")}
                </span>
              </div>
            </div>

            <div className="bg-white rounded-lg p-6 shadow">
              <h2 className="text-xl font-semibold mb-4">Description</h2>
              <p className="text-gray-600">{property.description}</p>
            </div>

            <div className="bg-white rounded-lg p-6 shadow">
              <h2 className="text-xl font-semibold mb-4">Facilities</h2>
              <p className="text-gray-600">{property.Facilities?.join(", ")}</p>
            </div>

            <div className="flex flex-col sm:flex-row gap-4">
              <button
                type="button"
                className="w-full sm:w-auto px-6 py-3 bg-[#f8545f] text-white rounded-lg hover:bg-rose-600 transition-colors"
                onClick={() => {
                  console.log("hi");
                }}
              >
                Download
              </button>

              {property.matterport && (
                <button
                  type="button"
                  className="w-full sm:w-auto px-6 py-3 bg-[#f8545f] text-white rounded-lg hover:bg-rose-600 transition-colors"
                  onClick={() => {
                    window.location.href = property.matterport;
                  }}
                >
                  Virtual Tour
                </button>
              )}
            </div>
          </div>
        </div>

        <div className="mt-12">
          <div className="bg-white rounded-xl shadow-lg overflow-hidden">
            <iframe
              title="Property Location"
              src={`https://maps.google.com/maps?q=${property.latitude},${property.longitude}&z=15&output=embed`}
              className="w-full h-[400px]"
            />
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

const RecoverPassword = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const userId = urlParams.get("userId");
  const secret = urlParams.get("secret");

  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [show, setShow] = useState(false);
  const openModal = () => setShow(true);
  const closeModal = () => setShow(false);
  if (show) {
    document.body.classList.add("block-overflow");
  }
  if (!show) {
    document.body.classList.remove("block-overflow");
  }

  if (!secret || !userId) {
    window.location.href = "/";
    return;
  }

  const HandleChangePassword = async () => {
    if (password !== passwordConfirm) {
      toast.error("Password and Confirm Password not match");
      return;
    }
    try {
      const response = await account.updateRecovery(
        userId,
        secret,
        password,
        passwordConfirm
      );
      console.log(response);
      toast.success("Password updated successfully", {
        onClose: () => {
          window.location.href = "/";
        },
        autoClose: 5000,
      });
    } catch (error) {
      toast.error("Error updating password", {
        autoClose: 5000,
      });
    }
  };

  return (
    <div>
      <ToastContainer className="toast" />
      <Modal closeModal={closeModal} show={show} />
      <Navbar openModal={openModal} closeModal={closeModal} show={show} />

      <div className="container">
        <div className="card">
          <h2>Reset your password</h2>

          <div className="inputBox">
            <label>New Password</label>
            <input
              type="password"
              name=""
              required=""
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <label>Confirm Password</label>
            <input
              type="password"
              name=""
              required=""
              value={passwordConfirm}
              onChange={(e) => setPasswordConfirm(e.target.value)}
            />
          </div>
          <input
            className="submit"
            type="submit"
            name=""
            value="Submit"
            onClick={HandleChangePassword}
          />
        </div>
      </div>

      <Footer />
    </div>
  );
};

const ContactUS = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const openModal = () => setShow(true);
  const closeModal = () => setShow(false);

  useEffect(() => {
    if (show) {
      document.body.classList.add("block-overflow");
    } else {
      document.body.classList.remove("block-overflow");
    }
  }, [show]);

  const HandleSubmitForm = async () => {
    const mailtoLink = `mailto:info@baitkw.com?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(
      `Name: ${name}%0D%0AEmail: ${email}%0D%0AMessage: ${message}`
    )}`;
    window.location.href = mailtoLink;
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <ToastContainer className="z-50" />
      <Modal closeModal={closeModal} show={show} />
      <Navbar openModal={openModal} closeModal={closeModal} show={show} />

      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="bg-white rounded-2xl shadow-lg p-8">
          <h2 className="text-3xl font-bold text-gray-900 text-center mb-8">
            اتصل بنا
          </h2>

          <div className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                اسمك (مطلوب)
              </label>
              <input
                type="text"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-rose-500 focus:border-transparent transition duration-200"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                بريدك الإلكتروني (مطلوب)
              </label>
              <input
                type="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-rose-500 focus:border-transparent transition duration-200"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                الموضوع
              </label>
              <input
                type="text"
                required
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-rose-500 focus:border-transparent transition duration-200"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                رسالتك (مطلوبة)
              </label>
              <textarea
                required
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                rows="4"
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-rose-500 focus:border-transparent transition duration-200 resize-none"
              />
            </div>

            <button
              onClick={HandleSubmitForm}
              className="w-full bg-[#f8545f] text-white font-medium py-3 px-6 rounded-lg hover:bg-rose-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500 transition duration-200"
            >
              إرسال رسالة
            </button>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

const Terms = () => {
  const [show, setShow] = useState(false);
  const openModal = () => setShow(true);
  const closeModal = () => setShow(false);
  if (show) {
    document.body.classList.add("block-overflow");
  }
  if (!show) {
    document.body.classList.remove("block-overflow");
  }

  return (
    <div>
      <ToastContainer className="toast" />
      <Modal closeModal={closeModal} show={show} />
      <Navbar openModal={openModal} closeModal={closeModal} show={show} />

      <div className="Terms">
        <h2>Terms and Conditions</h2>
        <p>
          1- Acceptance of Terms and Conditions:
          <br />
          <br />
          Registering as a member or posting an advertisement on the platform
          constitutes full and final acceptance of the terms and conditions.
          "Bait Real EstatePlatform" owns and operates the Bait website and
          application, providing advertising services for real estate brokerage
          companies, real estate intermediaries, and the management and
          marketing of third-party properties. The company is not responsible
          for the content or accuracy of advertisements published by real estate
          brokerage companies, and any use of the information provided on the
          platform is the user's responsibility. By agreeing to this, the user
          agrees not to hold the company accountable for any legal claims in
          this regard.
          <br />
          <br />
          2- User Account and Platform Registration Responsibilities:
          <br />
          <br />
          When registering as a member on the platform, you affirm that the
          information provided in the registration form (registration data) for
          Bait is true, accurate, current, complete, and officially represents
          you as an individual or as the legally authorized person to provide
          this information. You bear full legal responsibility for its use, and
          you agree to provide <strong id="baitcolor"> baitkw.com </strong>
          with any information or documentation upon request. Refusal to provide
          this information may result in restricting, suspending, or closing
          your account and membership. Registration as a member is limited to
          one account per person. By registering on the platform, you agree to
          maintain and update the required data to remain accurate and current.
          Providing false, inaccurate, outdated, or incomplete information, or
          if there is suspicion on our part regarding the authenticity or
          accuracy of the information, grants
          <strong id="baitcolor"> baitkw.com </strong> the right to suspend,
          limit, or close your account and membership without any claim on your
          part. When you become a member, you agree to be responsible for
          maintaining the privacy and restricting the use of your account and
          password. You agree to report any unauthorized use of your password or
          account or any security breach to{" "}
          <strong id="baitcolor"> baitkw.com </strong>.{" "}
          <strong id="baitcolor"> baitkw.com </strong> is not responsible for
          any direct or indirect loss resulting from the disclosure of your
          username and/or password. You cannot use another person's account
          without showing acceptance permission. Your membership implies your
          agreement to compensate <strong id="baitcolor"> baitkw.com </strong>{" "}
          for any unauthorized use of your account, whether by you or by any
          other person who can access the site and services using your username
          and password, whether you are aware of such use or not. You
          acknowledge your full and sole legal responsibility for the accuracy
          of any materials, information, data, or images you upload or post on
          the site. You also acknowledge that we are not responsible for these
          materials and bear full responsibility towards us and any third
          parties due to your non-compliance with this provision. Our agreement
          to publish any materials, information, data, or images you upload or
          post on the site does not in any way mean that we assume any
          responsibility arising from it. This site must not be used for
          copying, publishing, or sending any illegal or unlawful materials
          under any applicable laws and regulations. The site's content should
          not be defamatory, indecent, inappropriate, affecting public order and
          morals, misleading, inaccurate, deceptive, or violating the rights of
          third parties. This application should not be used in an illegal,
          unlawful, fraudulent, or harmful manner in any form. We reserve the
          right not to publish and/or delete any material, comment, or image
          that does not comply with the terms of this agreement or does not fit
          the site's policy. We also reserve the right to cancel registration if
          found. You agree to compensate us, defend us, and waive our liability,
          our partners, our lawyers, and our employees for any liability, loss,
          claim, or expense, including attorney's fees, related to your
          violation of the texts of this agreement or your use of the site.
          <br />
          <br />
          3- Suspension of Access or Membership Cancellation:
          <br />
          <br />
          <strong id="baitcolor"> baitkw.com </strong> has the right to suspend
          your activity, cancel your membership, and block your access to the
          site at any time without prior notice and without assuming any legal
          obligations, especially if you violate the terms of use, provide
          unverified information, or if your actions are deemed to involve legal
          risks. The site also reserves the right to recover any due amounts or
          seek compensation for resulting losses, taking legal action if
          necessary. Suspended members are not allowed to register or use the
          site in any way until their accounts are unbanned.
          <br />
          <br />
          4- Email Correspondence:
          <br />
          <br />
          By joining our membership, you consent to receiving communication from
          us via email, site notifications, or text messages. Consent to these
          communication methods fulfills any legal requirements for agreements
          and notifications. During registration, we will ask for your consent
          to receive promotional messages to keep you informed of the latest
          updates, features, and promotional activities. You can opt-out of
          receiving these promotional messages at any time by clicking the
          unsubscribe link at the bottom of the promotional messages you
          receive.
          <br />
          <br />
          5- User Agreement Modifications:
          <br />
          <br />
          By completing the registration process, you accept that{" "}
          <strong id="baitcolor"> baitkw.com </strong>
          has the authority to change the terms of the user agreement in ways
          that may increase your obligations or substantially reduce your rights
          according to the agreement's policies. You also agree that{" "}
          <strong id="baitcolor"> baitkw.com </strong>
          has the right to make non-substantial modifications to this agreement
          at any time, which will be announced on the site itself, without any
          commitment or responsibility.
          <br />
          <br />
          6- Improper Conduct with <strong id="baitcolor"> baitkw.com </strong>:
          <br />
          Please report any issues or violations of the terms of use on
          <strong id="baitcolor"> baitkw.com </strong>. If you believe someone
          has violated your intellectual property rights, please notify{" "}
          <strong id="baitcolor"> baitkw.com </strong>.{" "}
          <strong id="baitcolor"> baitkw.com </strong> reserves the right to
          restrict a user's access to the site, suspend their membership, or
          delete content posted by the user.{" "}
          <strong id="baitcolor"> baitkw.com </strong> can take technical or
          legal action against anyone causing problems, violating the law,
          including intellectual property rights violations or violating the
          site's terms of use or policies.
          <br />
          <br />
          7- Disclaimer of Warranty:
          <br />
          <br />
          <strong id="baitcolor"> baitkw.com </strong> and its services are
          provided "as is" and "as available" without making any representations
          or warranties, whether explicit or implicit, including but not limited
          to warranties of title, fitness for a particular purpose, security,
          accuracy, or freedom from infringement.{" "}
          <strong id="baitcolor"> baitkw.com </strong> does not guarantee the
          accuracy, completeness, or reliability of content, software, texts,
          images, links, or communications available through the site or its
          use, and does not guarantee the continuous operation of the site
          without errors or interruptions.{" "}
          <strong id="baitcolor"> baitkw.com </strong>
          also does not undertake to repair defects or that the site and its
          services are free from viruses or other damages. Due to the nature of
          the Internet, the site may experience malfunctions, data delays, or
          may be unavailable at times due to maintenance or updates. You
          acknowledge that your use of the site is at your own risk, and{" "}
          <strong id="baitcolor"> baitkw.com </strong> is not obligated to
          provide technical support for the site.
          <br />
          <br />
          8- Personal Data:
          <br />
          <br />
          When you use <strong id="baitcolor"> baitkw.com </strong>, you grant
          us permission to use all the data and information you provide, whether
          personal or otherwise, collected during registration, advertising,
          electronic communications, or through your use of any features on the
          site. This information will be used to enhance and manage the site in
          accordance with our user agreement and privacy policy.
          <br />
          <br />
          9- Intellectual Property:
          <br />
          <br />
          All content on <strong id="baitcolor"> baitkw.com </strong>, including
          texts, designs, logos, button icons, images, audio files, digital
          materials, databases, and software, is considered exclusive property
          and protected under copyright, trademark laws, patents, and other
          intellectual property rights and laws. This content, whether owned
          directly by
          <strong id="baitcolor"> baitkw.com </strong> or its users, is fully
          protected by law and subject to all existing copyright and
          intellectual property rights.
          <br />
          <br />
          10- Trademarks:
          <br />
          <br />
          <strong id="baitcolor"> baitkw.com </strong>, along with any logos,
          phrases, or icons associated with it displayed on the site, are
          trademarks, whether registered or unregistered, and are subject to
          international legal protection and intellectual property rights. The
          use of <strong id="baitcolor"> baitkw.com </strong> trademarks on
          products or services not part of{" "}
          <strong id="baitcolor"> baitkw.com </strong> or in any way that may
          damage the reputation of <strong id="baitcolor"> baitkw.com </strong>{" "}
          is prohibited.
          <br />
          <br />
          11- Privacy Protection:
          <br />
          <br />
          <strong id="baitcolor"> baitkw.com </strong> implements various
          protective measures (including physical, organizational, and
          technological measures) to protect and secure your personal data from
          unauthorized access. However, due to the nature of the Internet as an
          inherently insecure medium, absolute confidentiality of your personal
          information cannot be guaranteed.{" "}
          <strong id="baitcolor"> baitkw.com </strong> is not responsible for
          the actions of third parties that may affect your privacy, including
          sites that contain links to our site or parties engaged in identity
          theft. By sharing your personal information with us, you acknowledge
          and agree to its use for providing services and sending relevant
          advertisements, as outlined in our privacy policy that informs you
          about how your personal data is collected, processed, and transferred.
          <br />
          <br />
          12- Limitation of Liability:
          <br />
          <br />
          Within the limits permitted by law,{" "}
          <strong id="baitcolor"> baitkw.com </strong>, its directors,
          employees, affiliated entities, or providers do not assume any
          responsibility for any losses or damages, whether direct or indirect,
          incidental, special, consequential, or exemplary, including but not
          limited to loss of profits, business reputation, opportunities, data,
          or any other non-material losses resulting from your use of the site
          or its services or your relationship with the user agreement. If you
          are not satisfied with the site, its content, or the provided
          services, your only available option is to discontinue using them. You
          acknowledge that any unauthorized use of the site and your failure to
          take necessary actions may result in irreparable harm to{" "}
          <strong id="baitcolor"> baitkw.com </strong>, and such unauthorized
          uses will be dealt with according to the terms of use.
          <br />
          <br />
          13- Compensation Commitment:
          <br />
          <br />
          By using <strong id="baitcolor"> baitkw.com </strong>, you agree to
          compensate and protect the site, its subsidiaries, directors,
          employees, and shield them from any claims, disputes, actions,
          proceedings, losses, liabilities, or expenses, including reasonable
          legal costs and fees, arising from your violation of the terms of use
          or any laws or rights concerning third parties.
          <br />
          <br />
          14- Nature of the Relationship and Notifications:
          <br />
          <br />
          The provisions in the terms of use of{" "}
          <strong id="baitcolor"> baitkw.com </strong> do not constitute the
          establishment of any type of partnership or agency between you and the
          site, nor do they grant you any authority to impose obligations or
          represent <strong id="baitcolor"> baitkw.com </strong>.<br />
          15- Waiver of Rights and Obligations:
          <br />
          <br />
          Under the terms of this agreement, you acknowledge and agree finally
          that <strong id="baitcolor"> baitkw.com </strong> has the right at any
          time to transfer any part of its rights, responsibilities, or benefits
          arising from this agreement to any of its subsidiaries without
          requiring additional approval from you.{" "}
          <strong id="baitcolor"> baitkw.com </strong> will make reasonable
          efforts to inform you of such transfers by announcing them on the
          site. As a user, you do not have the right to transfer any of your
          rights or obligations arising from this agreement without obtaining
          prior written consent from{" "}
          <strong id="baitcolor"> baitkw.com </strong>.<br />
          16 - Links to Other Websites:
          <br />
          <br />
          Through this site, <strong id="baitcolor"> baitkw.com </strong>, you
          may access other sites that are not under the control of the company.
          By doing so, you agree and confirm that{" "}
          <strong id="baitcolor"> baitkw.com </strong> does not have control
          over the nature and content of these sites. The inclusion of any of
          these links does not imply the company's endorsement of the content
          published on these links or sites.
          <br />
          <br />
          17 - Commission and Fees Due to Bait App:
          <br />
          <br />
          In accordance with Ministerial Decision No. 164 of the year 2020
          regarding the regulation of practicing the real estate brokerage
          profession, the user acknowledges paying a commission to Bait Real
          Estate Platform, estimated at 1% of the property sale value (total
          price). This applies if the first party (the user) and the second
          party in the preliminary contract agree on the payment of the
          commission by the first party (the user) or the value (half-month
          rent) of the monthly rent for the leased unit, which becomes due
          immediately upon signing the preliminary contract or lease agreement.
          This is applicable if the property is sold or rented directly through
          Bait Real Estate Platform and not through companies or institutions
          contracting with Bait Real Estate Platform to showcase their
          properties on the platform. In case of non-payment of this commission,
          the user is obliged to pay an equivalent amount as compensation if
          legal action is taken to collect it.
          <br />
          <br />
          18 - General Provisions:
          <br />
          <br />
          If any provision of the user agreement is found to be illegal, void,
          or unenforceable for any reason, and this provision is separable from
          the rest of the provisions, it will not affect the validity and
          enforceability of the remaining provisions in the document. This user
          agreement, considering amendments that may occur from time to time,
          constitutes the entire understanding and agreement between you and{" "}
          <strong id="baitcolor"> baitkw.com </strong> regarding the matters
          covered herein. No person not a party to this agreement has the right
          to impose any terms of it.
          <br />
          <br />
          Governing Law: This user agreement follows and is subject to the laws
          applicable in the State of Kuwait.
        </p>

        <p id="ara">
          ١- قبول اتفاقية الاستخدام والشروط والأحكام:
          <br />
          <br />
          التسجيل كعضو أو الإعلان في المنصة يُعد قبولاً كاملاً ونهائياً للشروط
          والأحكام، وشركة منصة بيت العقارية تمتلك وتشغل موقع وتطبيق بيت، وهي
          منصة تقدم خدمات الإعلان لشركات السمسرة العقارية والوساطة العقارية
          وإدارة وتسويق أملاك الغير، دون تحمل مسؤولية عن محتوى أو دقة الإعلانات
          المنشورة من قبل شركات السمسرة العقارية، وأي إستخدام للمعلومات المقدمة
          عبر المنصة يكون على مسؤولية المستخدم الخاصة، والمستخدم يوافق بذلك على
          عدم محاسبة الشركة على أي دعاوى قانونية ناشئة بهذا الصدد.
          <br />
          <br />
          ٢- الالتزامات الخاصة بحساب المستخدم والتسجيل في المنصة:
          <br />
          <br />
          عند التسجيل كعضو في المنصة ، فإنك تتعهد وتقر بأن المعلومات التي تمنحها
          لنا في استمارة التسجيل ( بيانات التسجيل ) الخاصة ب بيت هي معلومات
          صحيحة ودقيقة و حالية وكاملة ورسمية عنك كفرد مستقل أو بأنك الشخص المخول
          قانونا بتقديم هذه المعلومات وتحمل المسؤولية القانونية الكاملة
          لاستخدامها وأنك ستوافق على اعطاء{" "}
          <strong id="baitcolor"> baitkw.com </strong> أي معلومات أو اثباتات عند
          الطلب. في حالة رفضك لإعطاء ما سبق ذكره فإن{" "}
          <strong id="baitcolor"> baitkw.com </strong>
          قد يقيد أو يوقف أو يغلق حسابك الخاص بالموقع و / أو عضويتك أيضاً. ولا
          يمكن لأي شخص التسجيل كعضو أكثر من مرة. عند التسجيل في المنصة فإنك
          توافق على أن تحافظ وتحدث البيانات المطلوبة عند التسجيل لتبقى دقيقة
          وحالية وكاملة. في حالة تقديم معلومات غير حقيقية أو غير دقيقة أو غير
          حالية أو غير كاملة، أو في حالة الاشتباه من طرفنا بأن هذه المعلومات غير
          حقيقية أو غير دقيقة أو غير حالية أو غير كاملة أو لا تنطبق مع اتفاقية
          المستخدم وغير خاضعة لقوانين و شروط{" "}
          <strong id="baitcolor"> baitkw.com </strong> ، فإن{" "}
          <strong id="baitcolor"> baitkw.com </strong> له الحق في إيقاف، وضع حد،
          اغلاق الحساب الخاص بك في الموقع و / أو عضويتك بالموقع دون أن يكون لك
          الحق في المطالبة بأي شيء عندما تصبح عضو، فإنك توافق على أنه يحق لـ
          <strong id="baitcolor"> baitkw.com </strong> بموجب اختياره و في أي وقت
          أن يقوم بأي استعلام يراه مهماً (سواء مباشرة أو عن طريق وسيط و أن يطلب
          منك اعطائه معلومات أو اثباتات أخرى بدون أي قيود لضمان هويتك و/أو
          ملكيتك لعهودك المالية. بالإضافة إلى أننا نحتفظ بأحقيتنا بوقف أي حساب
          غير مفعّل أو غير نشط لفترة طويلة من الزمن. عندما تصبح عضو، فإنك توافق
          على مسؤوليتك في الحفاظ على خصوصية وتقييد استخدام حسابك الخاص والرقم
          السري الخاص بك كما تقر بمسؤوليتك الكاملة تجاه الأنشطة التي تحدث من
          خلال حسابك الخاص ورقمك السري. وأنك ستقوم بإبلاغ{" "}
          <strong id="baitcolor"> baitkw.com </strong> عن أي استخدام غير مشروع
          لرقمك السري أو حسابك الخاص أو أي خرق أمنى. لن يكون{" "}
          <strong id="baitcolor"> baitkw.com </strong> مسئولاً عن أي خسارة
          مباشرة أو غير مباشرة بسبب الإفصاح عن اسم المستخدم الخاص بك و / أو رقمك
          السري. لا يمكنك استخدام الحساب الخاص بشخص آخر بدون اظهار تصريح القبول
          لحامل الحساب. كما أن عضويتك تعنى موافقتك على تعويض{" "}
          <strong id="baitcolor"> baitkw.com </strong> عن أي استخدام غير مشروع
          لحسابك الخاص عن طريقك أو عن طريق أي شخص آخر يستطيع الوصول للموقع و
          الخدمات من خلال استخدام اسم المستخدم الخاص بك و رقمك السرى سواء كنت
          تعلم أو لا تعلم بهذا الاستخدام وأنت تقر بمسؤوليتك القانونية الكاملة
          والمنفردة عن دقة أي مواد و/ أو معلومات و/ أو بيانات و/ أو صور تقوم
          بتحميلها و/ أو نشرها على الموقع كما أنك تقر بأن تلك المواد و/أو
          المعلومات و/ أو البيانات و/ أو الصور لا تخل أو تنتهك حقوق أطراف ثالثة
          وكذلك انت تقر بعدم مسؤوليتنا عن هذه المواد وتتحمل كامل المسؤولية
          اتجاهنا واتجاه أي أطراف ثالثة نتيجة عدم التزامك بهذا البند وان
          موافقتنا على نشر أي مواد و/أو معلومات و/ أو بيانات و / أو صور تقوم
          بتحميلها/ و / أو نشرها على الموقع لا تعني بأي شكل من الأشكال تحملنا أي
          مسؤولية ناشئة عنها. يجب عدم استخدام هذا الموقع لنسخ أو نشر أو إرسال أي
          مواد غير قانونية أو غير مشروعة بموجب أية قوانين ولوائح معمول بها، أية
          مواد يتم نسخها أو نشرها أو إرسالها من خلال هذا. الموقع يجب الا تكون
          تشهيرية، أو خادشه للحياء، أو غير لائقة، أو مؤثرة على السلم العام
          والآداب العامة، أو مضللة، أو غير دقيقة، أو خادعة أو تمثل انتهاك لحقوق
          الأشخاص، كما يجب عدم استخدام هذا التطبيق بطريقة غير قانونية، أو غير
          مشروعة، أو احتيالية، أو ضارة بأي شكل من الأشكال. يحق لنا عدم نشر و /
          أو حذف أي مادة أو تعليق أو صورة لا تتوافق مع شروط هذه الاتفاقية أو لا
          يتناسب مع سياسة الموقع كما يحق لنا الغاء التسجيل ان وجد كما أنك توافق
          على تعويضنا والمدافعة عنا وإخلاء مسؤوليتنا وشركائنا ومحامينا وموظفينا
          من أي مسؤولية، أو خسارة، أو ادعاء، أو نفقات بما في ذلك أتعاب المحاماة،
          فيما يتعلق بإخلالك لنصوص هذه الاتفاقية أو استعمالك للموقع.
          <br />
          <br />
          <br />
          ٣- تعليق الدخول أو إلغاء العضوية:
          <br />
          <br />
          يمتلك موقع <strong id="baitcolor"> baitkw.com </strong> الحق في تعليق
          نشاطك أو إلغاء عضويتك وحجب دخولك إلى الموقع في أي وقت دون إشعار مسبق
          ودون تحمل أي التزامات قانونية، خاصةً إذا خالفت شروط الاستخدام، أو في
          حالة عدم التحقق من صحة المعلومات التي قدمتها، أو إذا اعتبر{" "}
          <strong id="baitcolor"> baitkw.com </strong> أن تصرفاتك قد تنطوي على
          أخطار قانونية. كما يحق للموقع استرداد أي مبالغ مستحقة أو طلب تعويض عن
          الخسائر التي تسببت فيها، واتخاذ إجراءات قانونية إذا لزم الأمر. لا يسمح
          للأعضاء الموقوفين بالتسجيل أو استخدام الموقع بأي طريقة حتى يتم رفع
          الحظر عن حساباتهم.
          <br />
          <br />
          <br />
          ٤- المراسلة الإلكترونية:
          <br />
          <br />
          عند انتسابك لعضويتنا، تقر بموافقتك على استقبال التواصل منا عبر البريد
          الإلكتروني، إشعارات على الموقع، أو رسائل نصية قصيرة. تعتبر الموافقة
          على هذه الطرق من التواصل ملبية لأي متطلبات قانونية للاتفاقيات
          والإشعارات. خلال التسجيل، سنطلب منك الموافقة على استلام الرسائل
          الترويجية لاطلاعك على أحدث التحديثات والميزات والأنشطة الترويجية
          لدينا. يمكنك التوقف عن استلام هذه الرسائل الترويجية في أي وقت بالضغط
          على رابط إلغاء الاشتراك الموجود في أسفل الرسائل الترويجية التي
          تتلقاها.
          <br />
          <br />
          <br />
          ٥- تعديلات اتفاقية المستخدم:
          <br />
          <br />
          بإتمامك عملية التسجيل، تقبل بأن لدى{" "}
          <strong id="baitcolor"> baitkw.com </strong> السلطة لتغيير شروط
          اتفاقية الاستخدام بطرق قد تزيد من التزاماتك أو تقلل من حقوقك بشكل
          جوهري وفقًا لسياسات الاتفاقية. كما توافق على أن{" "}
          <strong id="baitcolor"> baitkw.com </strong> يحق له في أي وقت أن يجري
          تعديلات غير جوهرية على هذه الاتفاقية، والتي سيتم الإعلان عنها على
          الموقع نفسه، وذلك دون أي التزام أو مسؤولية.
          <br />
          <br />
          <br />
          ٦- التعامل الخاطئ مع <strong id="baitcolor"> baitkw.com </strong>:
          <br />
          يرجى الإبلاغ عن أية مشكلات أو تجاوزات لشروط الاستخدام عبر موقع
          <strong id="baitcolor"> baitkw.com </strong>. إذا ظننت أن أحدًا قد
          انتهك حقوق الملكية الفكرية الخاصة بك، يرجى الإخطار على
          <strong id="baitcolor"> baitkw.com </strong> . تحتفظ{" "}
          <strong id="baitcolor"> baitkw.com </strong> بالحق في تقييد وصول أي
          مستخدم إلى الموقع، تعليق عضويته، أو حذف المحتوى الذي ينشره المستخدم.
          كما يمكن لـ <strong id="baitcolor"> baitkw.com </strong> إتخاذ إجراءات
          تقنية أو قانونية ضد أي شخص يسبب مشكلات أو يخرق القانون، بما في ذلك
          انتهاكات حقوق الملكية الفكرية للغير أو مخالفة شروط استخدام الموقع أو
          سياساته.
          <br />
          <br />
          <br />
          ٧- إخلاء مسؤولية الضمان:
          <br />
          <br />
          يُتاح موقع <strong id="baitcolor"> baitkw.com </strong> وخدماته "كما
          هو" و"حسب المتاح" دون تقديم أي تعهدات أو تأكيدات، كما يخلي مسؤوليته عن
          جميع أنواع الضمانات، سواء كانت صريحة أو ضمنية، بما في ذلك على سبيل
          المثال لا الحصر ضمانات العنوان، أو الملاءمة لغرض معين، أو الأمان، أو
          الدقة، أو السلامة من الانتهاك. لا يضمن{" "}
          <strong id="baitcolor"> baitkw.com </strong> صحة وكمال وموثوقية
          المحتوى، أو البرمجيات، أو النصوص، أو الصور، أو الروابط، أو الاتصالات
          المتاحة عبر الموقع أو عبر استخدامه، ولا يضمن استمرارية عمل الموقع بدون
          أخطاء أو انقطاع. أيضًا، لا يتعهد{" "}
          <strong id="baitcolor"> baitkw.com </strong> بإصلاح الأعطال أو أن
          الموقع وخدماته خالية من الفيروسات أو الأضرار الأخرى. نظرًا لطبيعة
          الإنترنت، قد يعاني الموقع من الأعطال أو التأخير في البيانات أو قد يكون
          غير متاح أحيانًا بسبب الصيانة أو التحديثات. أنت تقر بأن استخدامك
          للموقع هو على مسؤوليتك الشخصية وأن{" "}
          <strong id="baitcolor"> baitkw.com </strong> لا يتوجب عليه تقديم الدعم
          الفني للموقع.
          <br />
          <br />
          <br />
          ٨- بياناتك الشخصية:
          <br />
          <br />
          عند استعمالك لموقع <strong id="baitcolor"> baitkw.com </strong>،
          تمنحنا الإذن لاستخدام جميع البيانات والمعلومات التي تقدمها، سواء أكانت
          شخصية أو غير ذلك، والتي تُجمع عند التسجيل، الإعلان، أو عبر الاتصالات
          الإلكترونية، أو من خلال استخدامك لأي ميزات في الموقع. سيتم استخدام هذه
          المعلومات لتعزيز وإدارة الموقع وفقًا لاتفاقية الاستخدام وسياسة
          الخصوصية الخاصة بنا.
          <br />
          <br />
          <br />
          ٩- الملكية الفكرية:
          <br />
          <br />
          جميع محتويات موقع <strong id="baitcolor"> baitkw.com </strong>، بما في
          ذلك النصوص، التصاميم، الشعارات، أيقونات الأزرار، الصور، ملفات الصوت،
          المواد الرقمية، قواعد البيانات، والبرمجيات، تُعتبر ملكية حصرية ومحمية
          بموجب قوانين حقوق النشر، العلامات التجارية، وبراءات الاختراع، وغيرها
          من الحقوق والقوانين المتعلقة بالملكية الفكرية. هذه المحتويات، سواء
          المملوكة مباشرةً لـ <strong id="baitcolor"> baitkw.com </strong> أو
          لمستخدميه، تحظى بحماية كاملة وفقًا للقانون وتخضع لجميع حقوق النشر
          والملكية الفكرية القائمة.
          <br />
          <br />
          <br />
          ١٠- العلامات التجارية:
          <br />
          <br />
          يشمل <strong id="baitcolor"> baitkw.com </strong>
          وأية شعارات أو عبارات أو أيقونات مرتبطة به والمعروضة على الموقع،
          علامات تجارية سواء كانت مسجلة أو غير مسجلة وهي خاضعة للحماية القانونية
          الدولية ولحقوق الملكية الفكرية. يُمنع استعمال العلامات التجارية الخاصة
          بـ <strong id="baitcolor"> baitkw.com </strong> على منتجات أو خدمات
          ليست جزءًا من <strong id="baitcolor"> baitkw.com </strong> أو بأي
          طريقة قد تلحق الضرر بسمعة
          <strong id="baitcolor"> baitkw.com </strong>.<br />
          <br />
          ١١- حماية الخصوصية:
          <br />
          <br />
          يقوم <strong id="baitcolor"> baitkw.com </strong>
          بتطبيق تدابير وقائية متنوعة (تشمل الإجراءات المادية، التنظيمية،
          والتكنولوجية) لحماية وتأمين بياناتك الشخصية من الوصول غير المصرح به.
          ومع ذلك، نظرًا لطبيعة الإنترنت كوسيط غير كامل الأمان، لا يمكن ضمان
          السرية المطلقة لمعلوماتك الشخصية.
          <strong id="baitcolor"> baitkw.com </strong> ليست مسؤولة عن أفعال
          الأطراف الثالثة التي قد تؤثر على خصوصيتك، بما في ذلك المواقع التي
          تحتوي على روابط لموقعنا أو الأطراف التي تقوم بانتحال الهوية. بمشاركتك
          لمعلوماتك الشخصية معنا، تقر وتوافق على استخدامها لتقديم الخدمات،
          وإرسال إعلانات تهمك، وفقًا لسياسة الخصوصية التي تُعلمك بكيفية جمع،
          معالجة، ونقل بياناتك الشخصية.
          <br />
          <br />
          <br />
          ١٢- تحديد المسؤولية:
          <br />
          <br />
          ضمن الحدود التي يسمح بها القانون، لا تتحمل{" "}
          <strong id="baitcolor"> baitkw.com </strong> أو مديريها، أو موظفيها،
          أو الكيانات المرتبطة، أو المزودون أي مسؤولية عن أية خسائر أو أضرار بأي
          شكل كانت، سواء كانت مباشرة أو غير مباشرة، تحدث بصورة عرضية، أو خاصة،
          أو تبعية، أو كنموذج، وتشمل دون حصر خسارة الأرباح، السمعة التجارية،
          الفرص، البيانات، أو أي خسائر أخرى غير مادية ناتجة عن استخدامك للموقع
          أو خدماته أو العلاقة مع اتفاقية المستخدم. إذا كنت غير مقتنع بالموقع أو
          محتواه أو الخدمات المقدمة، فالخيار الوحيد المتاح هو التوقف عن
          استخدامهم. كما تقر بأن أي استخدام غير مصرح به للموقع وتقاعسك عن اتخاذ
          الإجراءات اللازمة قد يؤدي إلى ضرر يصعب إصلاحه لـ{" "}
          <strong id="baitcolor"> baitkw.com </strong>، وسيتم التعامل مع مثل هذه
          الاستخدامات غير المصرح بها وفقًا لشروط الاستخدام.
          <br />
          <br />
          <br />
          ١٣- الالتزام بالتعويض:
          <br />
          <br />
          باستخدامك لـ <strong id="baitcolor"> baitkw.com </strong>، تلتزم بأن
          تقوم بتعويض وحماية الموقع، شركاته الفرعية، مديريه، موظفيه، وتحميهم من
          أية مطالبات، نزاعات، أعمال، إجراءات، خسائر، مسؤوليات، أو نفقات بما في
          ذلك تكاليف ومصاريف قانونية معقولة، التي قد تنجم عن انتهاكك لشروط
          الاستخدام أو لأي قوانين أو حقوق تخص أطراف ثالثة.
          <br />
          <br />
          <br />
          ١٤- طبيعة العلاقة والإخطارات:
          <br />
          <br />
          لا تعتبر الأحكام الواردة في شروط استخدام{" "}
          <strong id="baitcolor"> baitkw.com </strong> إنشاءً لأي نوع من أنواع
          الشراكة أو التوكيل بينك وبين الموقع، ولا تُمنح أي سلطة لك لتحميل{" "}
          <strong id="baitcolor"> baitkw.com </strong> أي التزامات أو تمثيل.
          <br />
          <br />
          <br />
          ١٥- التنازل عن الحقوق والالتزامات:
          <br />
          <br />
          بموجب شروط هذه الاتفاقية، تعترف وتوافق بصفة نهائية على أن
          <strong id="baitcolor"> baitkw.com </strong> له الحق في أي وقت بنقل أي
          جزء من حقوقها أو مسؤولياتها أو فوائدها الناتجة عن هذه الاتفاقية إلى أي
          من شركاتها الفرعية دون الحاجة إلى موافقتك الإضافية. ستقوم{" "}
          <strong id="baitcolor"> baitkw.com </strong> ببذل جهدها لإبلاغك بمثل
          هذه النقلات عبر الإعلان عنها على الموقع. أما أنت كمستخدم، فلا تملك
          الحق في نقل أي من حقوقك أو التزاماتك الناشئة عن هذه الاتفاقية دون
          الحصول على موافقة خطية مسبقة من{" "}
          <strong id="baitcolor"> baitkw.com </strong>. <br />
          <br />
          <br />
          ١٦- الروابط المتصلة بالمواقع الأخرى:
          <br />
          <br />
          من خلال هذا الموقع <strong id="baitcolor"> baitkw.com </strong> قد
          تكونوا قادرين على الوصول الى مواقع اخرى ليست تحت الشركة، وبذلك فأنكم
          توافقون وتأكدون بأن الشركة
          <strong id="baitcolor"> baitkw.com </strong> لا تملك السيطرة على طبيعة
          ومحتوى هذه المواقع، كما إن إدراج أي من هذه الروابط لا يعني تأييد
          الشركة للمحتويات التي تنشر في هذه الروابط أو المواقع. 
          <br />
          <br />
          <br />
          ١٧- العمولة والرسوم المستحقة لتطبيق بيت:
          <br />
          <br />
          حسب ما نص في القرار وزاري رقم 164 لسنة 2020 بشأن تنظيم مزاولة مهنة
          السمسرة العقارية يقر المستخدم بدفع عمولة شركة منصة بيت العقارية
          والمقدرة بـ 1٪ من قيمة بيع العقار (إجمالي الثمن) في حال إتفق الطرف
          الأول (المستخدم) والطرف الثاني في العقد الابتدائي على دفع العمولة من
          قبل الطرف الأول (المستخدم) أو قيمة (أجرة نصف شهر) من قيمة الأجرة
          الشهرية للوحدة المؤجرة والتي تعتبر مستحقة فور توقيع العقد الابتدائي أو
          عقد الإيجار في حالة تم بيعه أو ايجاره مباشرة عن طريق شركة منصة بيت
          العقارية وليس من خلال الشركات أو المؤسسات التي تتعاقد مع شركة منصة بيت
          العقارية لعرض عقاراتها في المنصة وفي حال عدم دفعه لهذه العمولة وأمتنع
          عنها يلتزم بدفع مثلها كتعويض في حال اللجوء الى القضاء لتحصيلها.
          <br />
          <br />
          <br />
          ١٨- الأحكام العامة:
          <br />
          <br />
          في حالة تبين أن أي من أحكام اتفاقية المستخدم غير قانوني أو باطل أو لا
          يمكن تطبيقه لأي سبب، وكان هذا البند منفصلاً عن باقي الأحكام، لن يؤثر
          ذلك على صلاحية وقابلية تنفيذ الأحكام الأخرى المتبقية في الوثيقة. تمثل
          اتفاقية شروط المستخدم هذه، بالنظر إلى التعديلات التي قد تطرأ عليها من
          وقت لآخر، كل الفهم والاتفاقيات بينك وبين
          <strong id="baitcolor"> baitkw.com </strong> بشأن الموضوعات المشمولة
          هنا. ولا يمتلك أي شخص ليس طرفاً في هذه الاتفاقية الحق في فرض أي شروط
          منها.
          <br />
          <br />
          <br />
          <br />
          القانون الحاكم 
          تتبع إتفاقية المستخدم هذه وتخضع للقانون المعمول به في دولة الكويت..
        </p>
        <br />
        <br />
      </div>

      <Footer />
    </div>
  );
};

const Privacy = () => {
  const [show, setShow] = useState(false);
  const openModal = () => setShow(true);
  const closeModal = () => setShow(false);
  if (show) {
    document.body.classList.add("block-overflow");
  }
  if (!show) {
    document.body.classList.remove("block-overflow");
  }

  return (
    <div>
      <ToastContainer className="toast" />
      <Modal closeModal={closeModal} show={show} />
      <Navbar openModal={openModal} closeModal={closeModal} show={show} />

      <div className="Terms">
        <h2>Privacy Policy</h2>
        <p>
          Bait - بيت built the Bait app as a Free app. This SERVICE is provided
          by Bait - بيت at no cost and is intended for use as is.
          <br />
          This page is used to inform visitors regarding our policies with the
          collection, use, and disclosure of Personal Information if anyone
          decided to use our Service.
          <br />
          If you choose to use our Service, then you agree to the collection and
          use of information in relation to this policy. The Personal
          Information that we collect is used for providing and improving the
          Service. We will not use or share your information with anyone except
          as described in this Privacy Policy.
          <br />
          The terms used in this Privacy Policy have the same meanings as in our
          Terms and Conditions, which are accessible at Bait unless otherwise
          defined in this Privacy Policy.
          <br />
          <strong>Information Collection and Use</strong>
          <br />
          For a better experience, while using our Service, we may require you
          to provide us with certain personally identifiable information,
          including but not limited to Name, Phone Number, Email. The
          information that we request will be retained by us and used as
          described in this privacy policy.
          <br />
          The app does use third-party services that may collect information
          used to identify you.
          <br />
          Link to the privacy policy of third-party service providers used by
          the app
          <br />
          <ul>
            <li>
              <a
                href="https://www.google.com/policies/privacy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Google Play Services
              </a>
            </li>
            <li>
              <a
                href="https://expo.io/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Expo
              </a>
            </li>
          </ul>
          <br />
          We want to inform you that whenever you use our Service, in a case of
          an error in the app we collect data and information (through
          third-party products) on your phone called Log Data. This Log Data may
          include information such as your device Internet Protocol (“IP”)
          address, device name, operating system version, the configuration of
          the app when utilizing our Service, the time and date of your use of
          the Service, and other statistics.
          <br />
          <strong>Cookies</strong>
          <br />
          Cookies are files with a small amount of data that are commonly used
          as anonymous unique identifiers. These are sent to your browser from
          the websites that you visit and are stored on your device's internal
          memory.
          <br />
          This Service does not use these “cookies” explicitly. However, the app
          may use third-party code and libraries that use “cookies” to collect
          information and improve their services. You have the option to either
          accept or refuse these cookies and know when a cookie is being sent to
          your device. If you choose to refuse our cookies, you may not be able
          to use some portions of this Service.
          <br />
          <strong>Service Providers</strong>
          <br />
          We may employ third-party companies and individuals due to the
          following reasons:
          <br />
          <ul>
            <li>To facilitate our Service;</li>{" "}
            <li>To provide the Service on our behalf;</li>{" "}
            <li>To perform Service-related services; or</li>{" "}
            <li>To assist us in analyzing how our Service is used.</li>
          </ul>
          <br />
          We want to inform users of this Service that these third parties have
          access to their Personal Information. The reason is to perform the
          tasks assigned to them on our behalf. However, they are obligated not
          to disclose or use the information for any other purpose.
          <br />
          <strong>Security</strong>
          <br />
          We value your trust in providing us your Personal Information, thus we
          are striving to use commercially acceptable means of protecting it.
          But remember that no method of transmission over the internet, or
          method of electronic storage is 100% secure and reliable, and we
          cannot guarantee its absolute security.
          <br />
          <strong>Links to Other Sites</strong>
          <br />
          This Service may contain links to other sites. If you click on a
          third-party link, you will be directed to that site. Note that these
          external sites are not operated by us. Therefore, we strongly advise
          you to review the Privacy Policy of these websites. We have no control
          over and assume no responsibility for the content, privacy policies,
          or practices of any third-party sites or services.
          <br />
          <strong>Children’s Privacy</strong>
          <br />
          These Services do not address anyone under the age of 13. We do not
          knowingly collect personally identifiable information from children
          under 13 years of age. In the case we discover that a child under 13
          has provided us with personal information, we immediately delete this
          from our servers. If you are a parent or guardian and you are aware
          that your child has provided us with personal information, please
          contact us so that we will be able to do the necessary actions.
          <br />
          <strong>Changes to This Privacy Policy</strong>
          <br />
          We may update our Privacy Policy from time to time. Thus, you are
          advised to review this page periodically for any changes. We will
          notify you of any changes by posting the new Privacy Policy on this
          page.
          <br />
          This policy is effective as of 2023-10-31
          <br />
          <strong>Contact Us</strong>
          <br />
          If you have any questions or suggestions about our Privacy Policy, do
          not hesitate to contact us at info@baitkw.com.
        </p>
      </div>

      <Footer />
    </div>
  );
};

const About = () => {
  const [show, setShow] = useState(false);
  const openModal = () => setShow(true);
  const closeModal = () => setShow(false);

  useEffect(() => {
    if (show) {
      document.body.classList.add("block-overflow");
    } else {
      document.body.classList.remove("block-overflow");
    }
  }, [show]);

  return (
    <div className="min-h-screen bg-gray-50">
      <ToastContainer className="z-50" />
      <Modal closeModal={closeModal} show={show} />
      <Navbar openModal={openModal} closeModal={closeModal} show={show} />

      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="bg-white rounded-2xl shadow-lg p-8">
          <h2 className="text-3xl font-bold text-gray-900 text-center mb-12">
            About Us
          </h2>

          <div className="space-y-8">
            <div className="prose max-w-none">
              <p className="text-lg text-gray-700 leading-relaxed mb-8">
                Bait is a real estate platform. Our goal is to satisfy the
                customer by using the best methods and technologies. Our goal is
                also to market your property in the latest ways to speed up the
                process of marketing your property and selling or renting it as
                soon as possible.
              </p>

              <p
                className="text-lg text-gray-700 leading-relaxed text-right"
                dir="rtl"
              >
                بيت هي منصة عقارية هدفنا ارضاء العميل باستخدام افضل الطرق
                والتقنيات وهدفنا ايضاً تسويق عقارك باحدث الطرق لتسريع عملية
                تسويق عقارك وبيعه او تأجيره باسرع وقت ممكن
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default App;
library.add(fab, fas, far);
